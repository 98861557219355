import "./LoadingGame.css"

function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
}
export default function LoadingGame() {
    return (
        <div className="loading-center">
            <LoadingSpinner />
            <p className="loading-text">Please Wait We are Loading Your Game</p>
        </div>
    );
}