import { useEffect } from "react";
import "./App.css";
import { UNINITIALIZED } from "./utility";
import { API_PATH } from "./utility";
import { Chart as ChartJS } from "chart.js/auto"
import { Line } from "react-chartjs-2";
import { useState } from "react";



export default function SeeAbility({ setGameState, user }) {

    //create an array of length 100 with values from 0 to 1 
    const data = Array.from({ length: 100 }, (_, i) => 1 / 100);
    const [ability, setAbility] = useState(data);
    function get_ability(user) {
        fetch(`${API_PATH}/get_user_ability/${user}`).then(
            response => {
                // setAbility(response.json().theta_array)
                response.json().then(
                    ability => {
                        console.log(ability)
                        console.log(ability.theta_array)
                        setAbility(ability.theta_array)
                    }
                )
                return response.json();
            }
        ).then(
            ability => {
                console.log(ability)
            }
        ).catch((reason) => { console.log("Failed to get user ability") })

    }
    useEffect(() => {
        console.log(get_ability(user))
    }, [user])
    return (
        <div>
            <div>
                <Line
                    data={{
                        labels: ability.map((value, index) => index / ability.length),
                        datasets: [
                            {
                                label: "Ability",
                                data: ability,
                                borderColor: "rgba(255,197,7,1)",
                                backgroundColor: "rgba(255,197,7,1)",
                                borderWidth: 1
                            }
                        ]
                    }}
                ></Line>
            </div>
            <button className="next-game-button" onClick={() => { setGameState(UNINITIALIZED) }}>Back to Game</button>
        </div >
    )

}