import './App.css';
import { useEffect, useState } from 'react';
import GameWindow from './GameWindow';
import SomethingWentWrong from './SomethingWentWrong';
import LoadingGame from './LoadingGame';
import Ready from './Ready';
import WhatsappShare from './WhatsappShare';
import SeeAbility from './SeeAbility';
//import everything from utility.js
import { GET_GAME_DATA, GET_USER_PATH, SEND_GAME_DATA, LOCAL_STORAGE_GAME_KEY, LOCAL_STORAGE_USER_KEY } from './utility';
import { SOMETHING_WENT_WRONG, UNINITIALIZED, READY_AND_GO, WON, LOST, CHECK_ABILITY, PLAYING } from './utility';



function reset_game_state() {
  localStorage.removeItem(LOCAL_STORAGE_GAME_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
}
function get_game_data_and_do(user, on_finish, on_error, on_user_not_found) {
  fetch(`${GET_GAME_DATA}/${user}`).then(
    response => {
      return response.json();
    }
  ).then(
    game_data => {
      // console.log(game_data)
      if (game_data === null) {

        console.log("Response for get game data was null--resetting game state");
        on_user_not_found();
        return;
      }
      on_finish(game_data);
    }
  ).catch((reason) => { on_error() });
}
function get_user_and_do(on_finish, on_error) {
  const user = window.localStorage.getItem(LOCAL_STORAGE_USER_KEY)
  if (user === null) {
    fetch(GET_USER_PATH).then(
      response => response.text()
    ).then(
      user => {
        console.log(user)
        localStorage.setItem(LOCAL_STORAGE_USER_KEY, user)
        on_finish(user, on_error);
      }
    ).catch((reason) => (on_error()))
    return;
  }
  on_finish(user, on_error)

}
function send_game_data(gameParameters, has_won, time_taken, idempotence_token, user) {
  gameParameters.game_state = has_won;
  gameParameters.time_taken_ms = time_taken
  gameParameters.user = user;
  gameParameters.idempotence_token = idempotence_token;
  console.log({ gameParameters })
  const json_data = JSON.stringify(gameParameters);
  console.log({ json_data })
  fetch(
    SEND_GAME_DATA,
    {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(gameParameters),
    }
  ).catch(() => { console.log("Failed to send user data") })

}

function get_user() {
  return localStorage.getItem(LOCAL_STORAGE_USER_KEY);
}
export default function App() {
  const [gameState, setGameState] = useState(UNINITIALIZED)
  const [lastResults, setLastResult] = useState('');
  const [gameKey, setGameKey] = useState(() => {
    if (localStorage.getItem(LOCAL_STORAGE_GAME_KEY) === null) {
      localStorage.setItem(LOCAL_STORAGE_GAME_KEY, 0);
    }
    return parseInt(localStorage.getItem(LOCAL_STORAGE_GAME_KEY))
  });
  const [timeTaken, setTimeTaken] = useState(0);
  const [gameParameters, setGameParameters] = useState(null);


  const increase_game_key = () => {
    setGameKey((x) => { localStorage.setItem(LOCAL_STORAGE_GAME_KEY, x + 1); console.log("game key" + x); return x + 1; });
  }

  const onGameEnd = (state, time_taken, solved) => {
    console.log(state);
    // console.log(solved);
    setTimeTaken(time_taken)
    setLastResult(solved)
    setGameState(state ? WON : LOST);
    const user = get_user();
    const idempotence_token = `${user}-${gameKey}`;
    send_game_data(gameParameters, state, time_taken, idempotence_token, user);
  }

  const on_error = () => {
    console.log("Set game state called")
    setGameState(SOMETHING_WENT_WRONG);
  }
  const on_user_not_found = () => {
    reset_game_state();
    setGameState(SOMETHING_WENT_WRONG);
    setGameKey(0);
  }

  const on_receive_game_data = (game_data) => {
    setGameState(READY_AND_GO);
    console.log(game_data)
    setGameParameters(game_data);
  }
  const on_check_ability = () => {
    setGameState(CHECK_ABILITY);
  }
  const on_go = () => {
    setGameState(PLAYING);
  }
  useEffect(
    () => {
      if (gameState !== UNINITIALIZED) { return; };
      // localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
      get_user_and_do(
        user => {
          console.log(user);
          get_game_data_and_do(
            user,
            on_receive_game_data,
            on_error,
            on_user_not_found
          );
        },
        on_error
      )
    }
    , [gameState]
  )
  if (gameState === SOMETHING_WENT_WRONG) {
    return <SomethingWentWrong onClickFunction={() => { setGameState(UNINITIALIZED) }} />
  }
  if (gameState === UNINITIALIZED) {
    return <LoadingGame />
  }

  if (gameState === READY_AND_GO) {
    return (
      <Ready game_parameters={gameParameters} on_click={on_go} />
    )

  }
  if (gameState === CHECK_ABILITY) {
    return <SeeAbility setGameState={setGameState} user={get_user()} />
  }

  return (
    <div className='center-app'>
      <GameWindow key={gameKey} onGameEnd={onGameEnd} gameParameters={gameParameters} />
      {(gameState !== PLAYING) && ((gameState === WON) ? <div className='congratz-text'>Congratz!</div> : <div className='congratz-text'>You'll get it next time!</div>)}
      {(gameState !== PLAYING) && <button className="next-game-button" onClick={() => { increase_game_key(); setGameState(UNINITIALIZED); }}>Next Game!</button>}
      {(gameState !== PLAYING) && <button className="next-game-button" onClick={() => { localStorage.removeItem(LOCAL_STORAGE_GAME_KEY); localStorage.removeItem(LOCAL_STORAGE_USER_KEY); setGameState(UNINITIALIZED) }}>Start Over</button>}
      {(gameState !== PLAYING) && <button className="next-game-button" onClick={() => { increase_game_key(); on_check_ability(); }}>See my Ability</button>}
      {(gameState !== PLAYING) && <WhatsappShare grid_value={lastResults} game_parameters={gameParameters} time_taken={timeTaken} />}

    </div >

  );
}

