import { useEffect } from 'react';
import './Game.css';
import { useState } from 'react';


function Square({ value, onclickFunction, state, style }) {
    return <button className={style} onClick={onclickFunction} >{state ? value : ' '}</button>;
}

function get_random_array(rows, columns) {
    let arr = Array(rows * columns).fill(0).map((_, i) => { return (i % Math.floor(rows * columns / 2) + 1); });
    return arr.sort(() => Math.random() - 0.5)
}

function get_style(state_solved, frozen) {
    let style = "square";
    if (state_solved) {
        return `${style} square-completed`;
    }
    if (!frozen) {
        return `${style} square-editable`;
    }
    return style
}

function Game({ rows, columns, revealTime, showTime, onFinish, externalFreeze, on_state_change }) {
    console.log(rows, columns)
    const [values, setValues] = useState(get_random_array(rows, columns));
    const [showStates, setShowStates] = useState(Array(rows * columns).fill(true));
    const [solved, setSolved] = useState(Array(rows * columns).fill(false));
    const [lastSelected, setLastSelected] = useState(-1);
    const [frozen, setFrozen] = useState(true);
    useEffect(
        () => {
            setValues(get_random_array(rows, columns))
            setShowStates(Array(rows * columns).fill(true));
            setFrozen(true)
            setLastSelected(-1);
            setSolved(Array(rows * columns).fill(false));
            setTimeout(() => {
                setShowStates(Array(rows * columns).fill(false));
                setFrozen(false);
            }, showTime)
        }, [rows, columns, showTime]
    );
    useEffect(
        () => {
            if (solved.every((v, i, a) => { return v; })) {
                onFinish();
            }
            console.log(solved);
            on_state_change(solved);
        },
        [solved, onFinish, on_state_change]
    )

    function state_changer(i, state) {
        return (x) => {
            x = x.slice();
            x[i] = state
            return x;
        }
    }
    function onclickFunction(index) {
        if (externalFreeze) {
            return;
        }
        if (showStates[index]) {
            return;
        }
        if (solved[index]) {
            return;
        }
        if (lastSelected === index) {
            return;
        }
        if (frozen) {
            return;
        }

        setShowStates(state_changer(index, true))
        if (lastSelected === -1) {
            setLastSelected(index);
            return;
        }

        if (values[index] === values[lastSelected]) {
            setSolved(state_changer(index, true));
            setSolved(state_changer(lastSelected, true));
        }
        else {
            setFrozen(true);
            setTimeout(() => {
                setShowStates(state_changer(index, false));
                setShowStates(state_changer(lastSelected, false));
                setFrozen(false);
            }, revealTime)
        }
        setLastSelected(-1);
    }
    const components = [];
    for (let r = 0; r < rows; r++) {

        const row = [];
        for (let c = 0; c < columns; c++) {
            let index = r * columns + c;
            row.push(
                <Square key={index} state={showStates[index]} value={values[index]} style={get_style(solved[index], frozen || externalFreeze)} onclickFunction={() => { onclickFunction(index) }} />
            );
        }
        components.push(
            <div key={r} className='board-row'>
                {row}
            </div>
        );
    }

    return components

}

export default Game;