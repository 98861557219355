import { useEffect } from 'react';
import { useState } from 'react';
import './CountDownTimer.css';

const TIME_UNIT_CONVERSION = 100;
const MS_IN_S = 1000;
const URGENT_LIMIT = 0.1
function time_centi_seconds_to_string(time_ms) {
    const seconds = Math.floor(time_ms / TIME_UNIT_CONVERSION);
    const c_seconds = time_ms % TIME_UNIT_CONVERSION;
    return `${seconds}:${String(c_seconds).padStart(2, '0')}`

}


function CountDownTimer({ start_time, running, onCountFinish }) {
    const [time, setTime] = useState(start_time * TIME_UNIT_CONVERSION);
    const [, setCountFinished] = useState(false);
    useEffect(
        () => {
            setTime(start_time * TIME_UNIT_CONVERSION)
            setCountFinished(false);
        }, [start_time]
    )
    useEffect(
        () => {
            if (!running) {
                setTime((time) => { onCountFinish(time / TIME_UNIT_CONVERSION * MS_IN_S, !running); return time; })
                return;
            }
            let interval_id = setInterval(
                () => {
                    setTime((x) => {
                        if (x === 0) {
                            setCountFinished(
                                val => {
                                    if (!val) {
                                        onCountFinish(x / TIME_UNIT_CONVERSION * MS_IN_S, !running);
                                    }
                                    return true;
                                }
                            )
                            return 0;
                        }
                        return x - 1;
                    });
                },
                MS_IN_S / TIME_UNIT_CONVERSION
            );
            return () => { clearInterval(interval_id); };
        }, [running, onCountFinish]
    );

    if (time < start_time * TIME_UNIT_CONVERSION * URGENT_LIMIT) {

        return <p className=' count-down-timer count-down-timer-urgent'>{time_centi_seconds_to_string(time)}</p>;
    }
    return <p className='count-down-timer'>{time_centi_seconds_to_string(time)}</p>;

}

export default CountDownTimer;