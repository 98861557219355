
export const SOMETHING_WENT_WRONG = -2
export const UNINITIALIZED = -1
export const PLAYING = 0;
export const READY_AND_GO = -3
export const WON = 1;
export const LOST = 2;
export const CHECK_ABILITY = 3;

export const API_PATH = "https://memoryquiz.orise.lk/api"
export const GET_USER_PATH = `${API_PATH}/new_user`
export const GET_GAME_DATA = `${API_PATH}/game_data`
export const SEND_GAME_DATA = `${API_PATH}/game_data`
export const GET_USER_ABILITY = `${API_PATH}/get_user_ability`


export const LOCAL_STORAGE_USER_KEY = 'memory_app_785868_user'
export const LOCAL_STORAGE_GAME_KEY = 'memory_app_785868_game_key'