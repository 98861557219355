
import "./WhatsappShare.css"

const SHARE_LINK = "whatsapp://send?text=";
const MEMORY_APP_LINK = "https://memoryquiz.orise.lk/"
function message(count, total, time_taken) {
    if (count === total) {
        return `Did it in ${time_taken / 1000}!`;
    }
    if (count - total < 4) {
        return `Almost Got it!`;
    }

    return `I'll get it next time!`;

}

function whatsapp_share(grid_value, time_taken, game_parameters) {
    let text = "";
    for (let i = 0; i < game_parameters.rows; i++) {
        for (let j = 0; j < game_parameters.columns; j++) {
            let index = game_parameters.columns * i + j;
            let v = grid_value[index] ? "🟨" : "🔳";
            text += v;

        }
        text += "%0A";
    }
    text += `Time limit is ${game_parameters.time_limit_ms / 1000} seconds have to match all the square!%0A`
    let count = grid_value.filter(x => x === true).length
    text += message(game_parameters.rows * game_parameters.columns, count, time_taken) + '%0A'
    text += `Check it out at ${MEMORY_APP_LINK}`;
    window.open(SHARE_LINK + text)
    // let link = `${SHARE_LINK}${text}`
    // window.open(link);
}


export default function WhatsappShare({ grid_value, time_taken, game_parameters }) {
    return <button onClick={() => whatsapp_share(grid_value, time_taken, game_parameters)} className="whatsapp-share-button">  <i className="fa fa-whatsapp"></i> Share on Whatsapp </ button>
    // return <button onClick={() => whatsapp_share()}>Share to Whatsapp</button>;

}
