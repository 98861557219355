import './App.css';
import { useState } from 'react';
import Game from './Game';
import CountDownTimer from './CountDownTimer';
export default function GameWindow({ onGameEnd, gameParameters }) {
    const [finished, setFinished] = useState(true);
    const [externalFreeze, setExternalFreeze] = useState(false);
    const [solved, setSolved] = useState(Array(gameParameters.rows * gameParameters.columns).fill(false));
    const onFinish = (time) => {
        setFinished(false);
    };
    const onCountFinish = (time, state) => {
        setExternalFreeze(true);
        onGameEnd(state, gameParameters.time_limit_ms - time, solved);
    }
    const onStateChange = (solved) => {
        setSolved(solved);
    }
    return (
        <div className='game'>
            <CountDownTimer start_time={gameParameters.time_limit_ms / 1000} running={finished} onCountFinish={onCountFinish} />
            <Game rows={gameParameters.rows} columns={gameParameters.columns} showTime={gameParameters.show_time_ms} revealTime={gameParameters.reveal_time_ms} onFinish={onFinish} externalFreeze={externalFreeze} on_state_change={onStateChange} />
        </div>
    )
}

