import "./Ready.css"
export default function Ready({ game_parameters, on_click }) {
    const time_limit = game_parameters.time_limit_ms / 1000;
    const show_time = game_parameters.show_time_ms / 1000;
    const rows = game_parameters.rows;
    const columns = game_parameters.columns;
    // const reveal_time = game_parameters.reveal_time_ms / 1000;

    return (
        <div className="ready-center">
            <div className="ready-text"> You will be given a grid of size {rows}x{columns}</div>
            <div className="ready-text"> You will have {time_limit - show_time} seconds to find all matching numbers</div>
            <div className="ready-text"> The numbers will be shown for {show_time} seconds before you begin</div>
            <div className="ready-text">Ready?</div>
            <div> </div>
            <button onClick={on_click} className="go-button">GO!</button>
        </div >
    )
}